import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { main, mainCategoryPage } from "../styles/components/Layout.module.css"

const Layout = ({ children, fadeInSpeed, fadeInDelay, isCategory, exitDelay = 0 }) => {
  return (
    <>
      <motion.main
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: typeof fadeInSpeed !== "undefined" ? fadeInSpeed : 0.3,
            delay: typeof fadeInDelay !== "undefined" ? fadeInDelay : 0,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.3,
            delay: exitDelay,
          },
        }}
        className={`${main} ${isCategory ? mainCategoryPage : ""}`}
        onAnimationComplete={(def) => {
          // def.opacity === 0 means the page has finished its exit animation
          if (def.opacity === 0 && typeof window !== "undefined") {
            window.scrollTo(0, 0)
          }
        }}
      >
        {children}
      </motion.main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
