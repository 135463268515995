import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import HTMLHead from "../components/HTMLHead"
import TalentIndex from "../components/Talent/TalentIndex"
import { catTitle } from "../styles/components/Category.module.css"

const Category = ({
  data: {
    allWpTalent: { nodes: allTalent },
    allWpCategory: { nodes: categories },
  },
  location,
}) => {
  const [filteredTalent, setFilteredTalent] = useState()
  const [category, setCategory] = useState()
  const [exitDelay, setExitDelay] = useState(0)

  const changeExitDelay = () => {
    setExitDelay(1)
  }

  useEffect(() => {
    // Don't change the talent if we're leaving the page
    const isLeavingPage = location && location.pathname !== "/talent/" && !location.pathname.startsWith("/talent/?category=")

    if (location?.search && !isLeavingPage) {
      const params = new URLSearchParams(location.search)
      const catSlug = params.get("category")
      if (catSlug) {
        const currCategory = categories.find((tmpCat) => tmpCat.slug === catSlug)
        setCategory(currCategory)
      }
    } else if (location && !isLeavingPage) {
      setCategory("all")
    }
  }, [categories, location])

  useEffect(() => {
    if (category === "all") {
      setFilteredTalent(allTalent)
    } else if (category) {
      const tmpTalent = allTalent.filter((talent) => {
        return talent.categories?.nodes?.find((cat) => cat.slug === category?.slug)
      })
      setFilteredTalent(tmpTalent)
    }
  }, [category, allTalent])

  const currCatTitle = category === "all" ? "All Talent" : category ? category.categoryFields.pluralName : ""

  return (
    <Layout key="category" isCategory={true} exitDelay={exitDelay}>
      <h1 className={catTitle}>{currCatTitle}</h1>
      {filteredTalent && <TalentIndex talents={filteredTalent} changeExitDelay={changeExitDelay} />}
    </Layout>
  )
}

export const query = graphql`
  {
    # Don't include talent without carousel images
    allWpTalent(filter: { hasCarousel: { eq: true } }, sort: { fields: lastNameLower }) {
      nodes {
        id
        title
        slug
        categories {
          nodes {
            slug
            name
          }
        }
        talentFields {
          carouselImages: carousel {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 225, height: 290, quality: 60)
              }
            }
          }
          displayName {
            first
            last
          }
        }
      }
    }
    allWpCategory(filter: { count: { gte: 1 } }, sort: { fields: slug }) {
      nodes {
        slug
        categoryFields {
          pluralName
        }
      }
    }
  }
`

export default Category

export const Head = () => <HTMLHead pageTitle="Talent" />
