import React from "react"
import TalentCard from "./TalentCard"
import { talentIndex, indexItem } from "../../styles/components/TalentIndex.module.css"

const TalentIndex = ({ talents, changeExitDelay }) => {
  return (
    <div className={talentIndex}>
      {talents.map((talent) => {
        return <TalentCard className={indexItem} key={talent.id} talent={talent} changeExitDelay={changeExitDelay} />
      })}
    </div>
  )
}

export default TalentIndex
