import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { card, thumbnail, gatsbyImageWrap, image, name } from "../../styles/components/TalentCard.module.css"
import ImageWrap from "../ImageWrap"

const TalentCard = ({ talent, className, changeExitDelay }) => {
  const [categoryString, setCategoryString] = useState("")
  const imageData = getImage(talent.talentFields.carouselImages[0]?.localFile)

  // Make category names into a string
  useEffect(() => {
    if(talent.categories.nodes.length){
      const categoryNames = talent.categories.nodes.map(({ name }) => name)
      const lastCatName = categoryNames.slice(-1)[0]

      if (categoryNames.length === 1) {
        setCategoryString(lastCatName)
      } else {
        const categoryCommaNames = categoryNames.slice(0, categoryNames.length - 1).join(", ")
        setCategoryString(`${categoryCommaNames} and ${lastCatName}`)
      }
    }
  }, [talent.categories.nodes])

  return (
    <div className={`${card} ${className}`}>
      <Link key={talent.id} to={`/talent/${talent.slug}/`} onClick={changeExitDelay}>
        {imageData && (
          <ImageWrap className={thumbnail}>
            <div className={gatsbyImageWrap}>
              <GatsbyImage image={imageData} alt={talent.title} objectFit="cover" className={image} />
            </div>
          </ImageWrap>
        )}
        <h3 className={name}>
          <span>{talent.talentFields.displayName.first}</span> <span>{talent.talentFields.displayName.last}</span>
        </h3>
        <h4>{categoryString}</h4>
      </Link>
    </div>
  )
}

export default TalentCard
