import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSiteOptions } from "../hooks/use-site-options"

const HTMLHead = ({ pageTitle, pageDescription, pageImageUrl, pageImageWidth, pageImageHeight }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const siteOptions = useSiteOptions()

  const fullTitle = `${siteMetadata.title} - ${pageTitle}`
  const description = pageDescription ?? siteMetadata.description

  const imageUrl = pageImageUrl ?? siteOptions.defaultSiteImage?.sourceUrl
  const imageWidth = pageImageUrl && pageImageWidth ? pageImageWidth : siteOptions.defaultSiteImage?.width
  const imageHeight = pageImageUrl && pageImageHeight ? pageImageHeight : siteOptions.defaultSiteImage?.height

  return (
    <>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <link rel="icon" href={siteOptions.siteFavicon?.sourceUrl} type="image/png" />
      <link rel="preload" href="/fonts/sloop/font.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
    </>
  )
}

export default HTMLHead
